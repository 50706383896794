import { NgModule } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  Routes,
  Event as RouterEvent,
} from '@angular/router';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { TrackingService } from './shared/services/tracking.service';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./modules/pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/pages/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'demos',
    loadChildren: () =>
      import('./modules/pages/demos/demos.module').then((m) => m.DemosModule),
  },
  {
    path: 'affiliation',
    loadChildren: () =>
      import('./modules/pages/affiliation/affiliation.module').then(
        (m) => m.AffiliationModule
      ),
  },
  // {
  //   path: 'one-time',
  //   loadChildren: () =>
  //     import('./modules/pages/one-time/one-time.module').then(
  //       (m) => m.OneTimeModule
  //     ),
  // },
  {
    path: 'faq',
    loadChildren: () =>
      import('./modules/pages/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./modules/pages/contact/contact.module').then(
        (m) => m.ContactModule
      ),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./modules/pages/pricing/pricing.module').then(
        (m) => m.PricingModule
      ),
  },
  {
    path: 'dashboard/account',
    loadChildren: () =>
      import('./modules/dashboard/account/account.module').then(
        (m) => m.AccountModule
      ),
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./modules/policies/policies.module').then(
        (m) => m.PoliciesModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private translate: TranslateService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private trackingService: TrackingService
  ) {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.updateBreadcrumbList((event as NavigationEnd).urlAfterRedirects);
        this.updateMetas((event as NavigationEnd).urlAfterRedirects);
        this.removeSchemaOrg((event as NavigationEnd).urlAfterRedirects);
        this.trackingService
          .sendAppData({
            event_name: 'PageView',
            data: {
              path: (event as NavigationEnd).urlAfterRedirects,
              page_title: this.title.getTitle(),
            },
          })
          .subscribe();
      });
  }

  updateMetas(url: string) {
    const segments = url
      .split('?')[0]
      .split('/')
      .filter((segment) => segment);
    const lastSegment =
      segments.length === 0 ? ['home'] : segments[segments.length - 1];

    const metaTitle = this.translate.instant(
      `meta_datas.${lastSegment}.meta_title`
    );
    const metaDescription = this.translate.instant(
      `meta_datas.${lastSegment}.meta_description`
    );
    const metaIndex = this.translate.instant(`meta_datas.${lastSegment}.index`);

    this.title.setTitle(
      metaTitle != `meta_datas.${lastSegment}.meta_title`
        ? metaTitle
        : this.translate.instant('meta_datas.404.meta_title')
    );

    this.meta.updateTag({
      name: 'description',
      content:
        metaDescription != `meta_datas.${lastSegment}.meta_description`
          ? metaDescription
          : this.translate.instant('meta_datas.404.meta_description'),
    });

    this.meta.updateTag({
      name: 'robots',
      content:
        metaIndex != `meta_datas.${lastSegment}.index`
          ? metaIndex === true
            ? 'index,follow'
            : 'noindex,nofollow'
          : 'index,follow',
    });
  }

  updateBreadcrumbList(url: string) {
    const breadcrumbList: any = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [],
    };

    const segments = url.split('/').filter((segment) => segment);
    let breadcrumbUrl = '';

    breadcrumbList.itemListElement.push({
      '@type': 'ListItem',
      position: 1,
      name: this.translate.instant('meta_datas.home.meta_title'),
      item: 'https://phantom-theme.fr',
    });

    segments.forEach((segment, index) => {
      breadcrumbUrl += `/${segment}`;
      breadcrumbList.itemListElement.push({
        '@type': 'ListItem',
        position: index + 2,
        name:
          this.translate.instant(`meta_datas.${segment}.meta_title`) || segment,
        item: `https://phantom-theme.fr${breadcrumbUrl}`,
      });
    });

    this.injectBreadcrumbSchema(breadcrumbList);
  }

  removeSchemaOrg(url: string) {
    switch (url) {
      case '/':
        document.getElementById('websiteSchema') &&
          document.getElementById('websiteSchema')?.remove();
        document.getElementById('organizationSchema') &&
          document.getElementById('organizationSchema')?.remove();
        break;
      case '/faq':
        document.getElementById('faqSchema') &&
          document.getElementById('faqSchema')?.remove();
        break;
      case '/pricing':
        document.getElementById('productsSchema') &&
          document.getElementById('productsSchema')?.remove();
        break;
      case '/contact':
        document.getElementById('contactSchema') &&
          document.getElementById('contactSchema')?.remove();
        break;
      default:
        break;
    }
  }

  injectBreadcrumbSchema(breadcrumbList: any) {
    let breadcrumbSchema: HTMLScriptElement;

    if (!document.getElementById('breadcrumbSchema')) {
      breadcrumbSchema = document.createElement('script');
      breadcrumbSchema.type = 'application/ld+json';
      breadcrumbSchema.id = 'breadcrumbSchema';
    } else {
      breadcrumbSchema = document.getElementById(
        'breadcrumbSchema'
      ) as HTMLScriptElement;
    }

    breadcrumbSchema.text = JSON.stringify(breadcrumbList);
    document.head.appendChild(breadcrumbSchema);
  }

  ngOnInit(): void {}
}
